@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

html, body, #root, .container-fluid, .row {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.container-fluid {
  display: flex; /* Ensure flexbox layout */
  height: 100%; /* Ensure the container takes up the full height of the screen */
  width: 100%;
}

.sidebar {
  padding: 20px;
  background-color: #F8F5EF;
  width: 50px; /* Start with collapsed width */
  position: relative;
  height: 100vh;
  transition: width 0.3s ease; /* Smooth transition for width change */
  overflow: hidden; /* Hide overflow when collapsed */
  white-space: nowrap; /* Prevent text from wrapping */
}

.sidebar.expanded {
  width: 250px; /* Width when expanded */
}

.sidebar .nav-link .icon {
  font-size: 1.5rem; /* Increase the font size */
  margin-right: 10px;
  flex-shrink: 0;
}

.sidebar .nav-link {
  display: flex;
  align-items: center;
  color: #18A04F;
  overflow: hidden;
  padding: 10px 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar .nav-link .icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.sidebar.collapsed .nav-link .link-text {
  display: none !important; /* Force hiding of text when collapsed */
}

.sidebar.collapsed .nav-link .icon {
  margin-right: 0; /* Remove margin when sidebar is collapsed */
}

.sidebar.collapsed .nav-link::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 100%;
  white-space: nowrap;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
  transform: translateX(-10px);
}

.sidebar.collapsed .nav-link:hover::after {
  opacity: 1;
  transform: translateX(0);
}

.sidebar .nav-link:hover {
  background-color: #18A04F;
  color: #fff;
  border-radius: 4px;
}

.sidebar:hover {
  width: 250px; /* Expand on hover */
}

.content {
  display: flex;
  flex-grow: 1; /* Ensures the content grows to fill available space */
  justify-content: flex-start;
  padding: 20px;
  height: 100vh; /* Set the height to fill the viewport */
  overflow-y: auto;
  width: 100%; /* Ensure it takes up the full width */
  transition: margin-left 0.3s ease; /* Only adjust margin based on sidebar state */
}


.sidebar.expanded + .content {
  width: calc(100% - 250px); /* Adjust width based on expanded sidebar */
}

.sidebar.collapsed + .content {
  margin-left: 80px; /* Adjust content margin when sidebar is collapsed */
}

.navbar, .btn-outline-success {
  background-color: #F8F5EF !important; /* Same color as the sidebar */
  height: 50px; /* Set the height of the navbar */
  padding: 0; /* Remove any padding to ensure the height is exactly 50px */
}

.navbar-brand img {
  height: 24px; /* Adjust the logo height */
  margin-right: 10px;
  margin-left: 35px;
  margin-top: 10px;
}

.navbar .navbar-brand,
.navbar .nav-link {
  line-height: 30px; /* Vertically center the text and icons */
  font-size: 1rem; /* Adjust font size if necessary */
}

.btn-outline-success {
  color: #fff;
}

.navbar .form-control {
  height: 30px; /* Match the form control height to the navbar */
  font-size: 0.875rem; /* Adjust font size if needed */
}

.nav-link {
  color: #18A04F;
}

.nav-link.active {
  font-weight: bold;
}

.hub-selector {
  display: flex;
  align-items: center;
  color: #18A04F;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer; /* Add this line to show a hand cursor on hover */
}


.hub-selector .hub-name {
  margin-left: 5px;
}

.hub-selector .caret {
  margin-left: 5px;
  font-size: 0.875rem;
  color: #999;
}

/* Dropdown styles */
.dropdown-toggle {
  display: flex;
  align-items: center;
}

.dropdown-toggle::after {
  margin-left: 10px;
  border: solid #18A04F;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: calc(100% + 10px); /* Ensure it appears below the trigger */
  left: 100;
  z-index: 1000;
  display: block;
  min-width: 200px; /* Set a minimum width for the dropdown */
  max-width: 100%; /* Prevent it from being too wide */
  margin-top: 0.125rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  background-color: #fff;
  animation: fadeIn 0.3s ease-in-out;
}

/* Ensure dropdown stays within viewport */
.dropdown-menu.show {
  right: auto; /* Reset right positioning */
  top: 80px;
  transform: translateY(0);
}

/* Dropdown Items */
.dropdown-item {
  color: #333;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  display: block;
  width: 100%;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

/* Special Create New Hub Item */
.dropdown-item.create-new {
  font-weight: bold;
  color: #18A04F;
}

.dropdown-item.create-new:hover {
  background-color: #e0e0e0;
}

/* Animation for dropdown */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Dashboard styles */
.dashboard {
  padding: 20px;
  margin-right: auto; /* Ensure the dashboard stays to the left */
  max-width: 100%; /* Ensure it doesn't exceed its container */
  background-color: #fff; /* Distinct background */
}

.session-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  padding: 20px;
  margin-right: auto;
  overflow: auto;
}

.dashboard h2 {
  margin-bottom: 20px;
  color: #18A04F;
  font-weight: 600;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table-container {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Apply box-shadow */
  border-radius: 8px; /* Optional, for rounded corners */
}


/* Button to go back to companies */
.btn.btn-secondary {
  background-color: #18A04F;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
}

.btn.btn-secondary:hover {
  background-color: #145C32;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #F8F5EF; /* Updated background color for headers */
  color: #333;
}

td {
  background-color: #fff;
}

audio {
  width: 100%;
  max-width: 200px;
}

.error {
  color: red;
  margin-bottom: 20px;
}

/* Spinner styles */
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-message {
  color: red;
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
}

/* Center the login page */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;  /* Light background for contrast */
}

/* Login container styles */
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f0f0f0;
}

/* Form group styling */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  text-align: left;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

/* Button styles */
.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Message styles */
.login-message {
  margin-top: 1rem;
  color: red;
}


.error-message {
  color: red;
  text-align: center;
  margin-top: 1rem;
}

.subscription-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background with transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure it covers the entire screen */
}

.modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

p {
  color: #666;
  font-size: 18px;
}

.transcription {
  background-color: #f9f9f9; /* Light background for contrast */
  padding: 15px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  white-space: pre-wrap; /* Ensures line breaks are preserved */
}
.transcription h3 {
  font-size: 1.2rem;
  color: #18A04F;
  margin-bottom: 10px;
}

.table-container h3 {
  margin-bottom: 10px;
  color: #333;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #F8F5EF;
}

td {
  background-color: #fff;
}

.box-shadow-container {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Apply box-shadow */
  border-radius: 8px;
}